.name-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20.0667px;
  line-height: 24px;
  /* identical to box height */
  color: #045229;
}
.name-title1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20.0667px;
  line-height: 24px;
  /* identical to box height */
  color: #01c3ac !important;
}
.detail-div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 16px;
  color: #4c4c69;
  //   padding-bottom: 26px !important;
  width: 40%;
  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
}
.data-div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #030229;
  // margin-top: 26px;
  @media screen and (max-width: 1024px) {
    font-size: 13px;
  }
}
.flex-name {
  display: flex;
  justify-content: space-evenly;
  background: #ffffff;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.detail-row {
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  padding-top: 45px;
  padding-bottom: 45px;
  margin-left: 4px;
  margin-right: 4px;
}
.p-b-16 {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-around;
}
.detail-row2 {
  background: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
}
.margin-left-20 {
  margin-left: 50px;
}
.image-style {
  width: 125px;
  height: 125px;
}
.w-20 {
  width: 180px;
  @media screen and (max-width: 1024px) {
    width: 125px !important;
  }
}
.color-name {
  color: #0c1e5b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.margin-top-26 {
  margin-top: 26px;
}
.detail-col {
  background: #ffffff;
  border-radius: 5px;
  padding: 28px;
}
.d-flex-evently {
  display: flex;
  justify-content: space-evenly;
  .col-lg-4 {
    flex: 0 0 auto;
    width: 48.333333%;
  }
}
.margin-top-20 {
  margin-top: 20px;
}
.m-r-25 {
  margin-right: 25px;
}
.float-right {
  float: right;
}
.cercle {
  width: 210px;
  height: 210px;
}
.image-style1 {
  width: 112px;
  height: 112px;
}
.segmented-control > label:first-of-type:nth-last-of-type(4),
.segmented-control > label:first-of-type:nth-last-of-type(4):after,
.segmented-control > label:first-of-type:nth-last-of-type(4):before,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:before {
  width: 5% !important;
  height: 10px;
  border-radius: 2px;
}
.segmented-control {
  width: 80% !important;
  // color: rgb(171, 71, 188);
  color: #4c4c69;
  border-radius: 1px !important;
}
.red-color {
  background-color: red;
  border: 1px solid black;
  border-radius: 3px;
  width: 26px !important;
  height: 10px;
  @media screen and (max-width: 1280px) {
    width: 20px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 15px !important;
  }
}
.green-color {
  width: 15px !important;
  background-color: #01c3ac;
  border-color: #4c4c69;
  width: 26px !important;
  height: 10px;
  border-radius: 3px;
  @media screen and (max-width: 1280px) {
    width: 20px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 15px !important;
  }
}
.white-color {
  width: 26px !important;
  height: 10px;
  border-radius: 10px;
  border: 1px solid black;
  @media screen and (max-width: 1280px) {
    width: 20px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 15px !important;
  }
}
.gray-color {
  width: 15px !important;
  background-color: gray;
  width: 26px !important;
  height: 10px;
  border-radius: 3px;
  border: 1px solid black;
  @media screen and (max-width: 1280px) {
    width: 20px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 15px !important;
  }
}
.display-flex-1 {
  display: flex;
  margin-top: 10px;
}
.d-flex-between {
  display: flex;
  justify-content: space-between;
}
.color-kinsa {
  color: gray;
}
.color-red-text {
  color: red;
}
.color-green-text {
  color: green;
}
.table_match {
  display: grid;
  grid-template-columns: 1fr 80%;
  // max-width: 480px;
  grid-column-gap: 10px;
  grid-row-gap: 1em;
}
.table_match_detail {
  display: grid;
  grid-template-columns: 5fr 1fr 78% 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 1280px) {
    grid-template-columns: 5fr 1fr 68% 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 5fr 1fr 60% 1fr 1fr 1fr 1fr;
  }
  grid-column-gap: 2px;
  grid-row-gap: 1em;
}
.margin-left-3 {
  margin-left: 3rem;
}
.width-table {
  width: 74% !important;
}
.th-width-table {
  width: 49%;
}
