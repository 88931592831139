.paginate {
  ul {
    display: flex;
    padding-left: 0;
    padding-top: 1rem;
    list-style: none;
    justify-content: flex-end;
    li {
      a {
        font-family: "Roboto-bold";
        font-size: 14px;
        margin-right: 5px;
        color: #1c205d !important;
        padding: 5px 12px 5px 12px;
        text-decoration: none !important;
      }
      a:hover {
        color: #0f67d9 !important;
        cursor: pointer !important;
      }
    }
    li.previous,
    li.next {
      a {
        color: #b1b1b1 !important;
      }
    }
    li.active a {
    //   color: #0f67d9 !important;
      cursor: pointer !important;
      border-radius: 21px;
      width: 50px;
      height: 50px;
      background-color: #FF6966 !important;
    }
    li.disable,
    li.disabled {
      a {
        color: rgb(145, 158, 171, 0.5) !important;
        // color: #fff !important;
        cursor: default !important;
      }
      a:hover {
        // background-color: rgb(145, 158, 171, 0.5) !important;
        color: rgb(145, 158, 171, 0.5) !important;
        cursor: default !important;
      }
    }
  }
}
.cursor-bottom {
  cursor: pointer !important;
}