.main {
  padding: 50px 20px 100px 250px;
  background-color: #f5f9ff;
  min-height: 100vh;
}
.login {
  background-color: #f5f9ff;
}
.login-form {
  background: #ffffff;
  /* Drop Shadow */
  width: 620px;
  height: 550px;
  box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
  border-radius: 5px;
  text-align: -webkit-match-parent;
}
body {
  background-color: #f5f9ff;
  height: 100%;
}
.title-paginate {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  /* Primary/0C1E5B Text */
  color: #0c1e5b;
}
.button-upload {
  background: #ff6966;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: space-around;
  padding: 17px;
  cursor: pointer !important;
}
.text-button-upload {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.display-title {
  display: flex !important;
  justify-content: space-between !important;
}
.table th {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  color: #4c4c69;
}
.table {
  margin-top: 40px;
  // width: 50%;
}
.table tbody td {
  background: #ffffff;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #030229;
  margin-top: 50px;
  @media screen and (max-width: 1024px) {
    font-size: 12px !important;
  }
}
.table tbody td span.gender {
  color: #5b93ff;
  background-color: rgba($color: #5b93ff, $alpha: 0.1);
  padding: 4px 20px;
  border-radius: 33px;
  font-family: "Nunito";
  font-style: normal;
  font-size: 14px;
  @media screen and (max-width: 1024px) {
    padding: 4px 5px !important;
    font-size: 12px !important;
  }
}
.w-100 {
  width: 100% !important;
}

.image-cursor {
  cursor: pointer !important;
  padding-right: 10px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ff6966;
  border-color: #ff6966;
  background: #ff6966 !important;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none;
  border-width: 0;
  //  border-collapse: separate;
}
.lignesEspacees {
  border-collapse: separate;
  border-spacing: 0px 13px;
}
.judo-logo {
  width: 56px;
  text-align: center;
  margin-left: 70px;
}
.mr-5 {
  margin-right: 1rem;
  @media screen and (max-width: 1024px) {
    margin-right: 0.1rem;
  }
}
.border-genre {
  background: #5b93ff;
  opacity: 0.1;
  border-radius: 33px;
}
.font-genre {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #5b93ff;
}
.m-r {
  margin-right: 3rem;
}
.m-l {
  margin-left: 1rem;
  @media screen and (max-width: 1024px) {
    margin-left: 0.3rem;
  }
}
.mr-1 {
  margin-right: 1rem;
  @media screen and (max-width: 1024px) {
    margin-right: 0.3rem;
  }
}
.w-90 {
  width: 35%;
}
.w-40 {
  width: 30%;
}
.margin-top-60 {
  margin-top: 60px;
}
.cercle-green {
  background-color: green;
  width: 5px;
  height: 5px;
  margin-top: 10px;
  margin-right: 3%;
  margin-left: 6%;
}
.cercle-red {
  width: 5px;
  height: 5px;
  background-color: #ff0000;
  margin-top: 10px;
  margin-right: 3%;
  margin-left: 6%;
}
.title-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0c1e5b;
}
.button-connect {
  background: #ff6966;
  border-radius: 10px;
  margin-top: 54px;
}
.width-input {
  width: 450px;
  height: 50px;
}
.margin-input-bottom {
  margin-bottom: 30px;
}
.title-login {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 30px;
  /* Primary/0C1E5B Text */
  color: #0c1e5b;
}
.width-td {
  width: 480px !important;
}
.width-td-vs {
  width: 2em !important;
  height: 2em !important;
  border-radius: 60px;
  @media screen and (max-width: 1024px) {
    width: 1.2em !important;
    height: 1.2em !important;
  }
}
.place-icon {
  @media screen and (max-width: 1024px) {
    width: 1.2em !important;
  }
}
.size-place-td {
  @media screen and (max-width: 1024px) {
    width: 94px !important;
  }
}
.col-lg-3 {
  @media screen and (max-width: 1024px) {
    flex: 0 0 auto;
    width: 32%;
  }
}
.col-lg-4 {
  @media screen and (max-width: 1024px) {
    flex: 0 0 auto;
    width: 96% !important;
  }
}
.display-flex-between {
  display: flex;
  justify-content: space-between;
}
.width-74 {
  width: 74%;
}
.custom_td {
  td {
    background: #f5f9ff !important; 
    border-radius: 5px;
    padding-top: 0px !important; 
    padding-bottom: 0px !important;
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    line-height: 27px !important;
    color: #030229;
    margin-top: 9px !important; 
  }
}
.padding-right-div {
  padding-right: 10px;
}
.display-flex-button {
  display: flex;
}