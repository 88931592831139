.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: 237px;
  background-color: #ffffff;
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}
#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
.nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  align-items: center;
  color: #4c4c69;
  text-decoration: none;
  margin-left: 31px;
}
.nav-link.is-active {
  background: lightsalmon !important;
}
.sidebar-sticky {
  margin-left: 31px;
  margin-bottom: 55px;
}
.nav-item {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.sidebar-logo {
  margin-top: 21%;
}
.margin-left-img {
  margin-left: 30px;
}
