.font-title-cercle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 3%;
}
.color-red {
  color: #ff0000;
}
.color-green {
  color: #01c4ac;
}
.margin-bottom-3 {
  margin-bottom: 1rem;
}